@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@layer base{
    body{
        @apply font-[Inter];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.fade-in {
    opacity: 0;
    transition: opacity 1s ease-out, transform 1s ease-out;
    transform: translateY(20px);
  }
  
  .fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }

  .content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}